import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_sLxaNGmlSL from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import luzmo_client_config_AlGxfm1aiK from "/app/plugins/luzmo.client.config.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/app/plugins/Vue3Lottie.client.ts";
import auth_plugin_cco9pff4vd from "/app/plugins/auth-plugin.ts";
import date_picker_cVMkKXOe4l from "/app/plugins/date-picker.ts";
import errors_handler_0pt58gBTEw from "/app/plugins/errors.handler.ts";
import helpscout_client_EOdKpmWm8J from "/app/plugins/helpscout.client.ts";
import localize_client_QxlArF5CO9 from "/app/plugins/localize.client.ts";
import sentry_client_config_o5jjUx2pcO from "/app/plugins/sentry.client.config.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  luzmo_client_config_AlGxfm1aiK,
  Vue3Lottie_client_bMZiOS6AB0,
  auth_plugin_cco9pff4vd,
  date_picker_cVMkKXOe4l,
  errors_handler_0pt58gBTEw,
  helpscout_client_EOdKpmWm8J,
  localize_client_QxlArF5CO9,
  sentry_client_config_o5jjUx2pcO
]
import * as Sentry from "@sentry/vue";

// Global handler for Server and Client Startup Errors (SSR + SPA)
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    const environment: string = nuxtApp.$config.ENV as string;
    if (["DEV", "PROD"].includes(environment)) {
      Sentry.captureException(error, context?.$data);
    } else {
      // eslint-disable-next-line
      console.log(error);
    }
  };
});
